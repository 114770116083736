import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const PageHeader = ({children}) => {
  return(
    <div className="pageHeader">
      <Container className="my-5">
        <Row>
          <Col>
            { children }
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PageHeader;
