import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const ContainerWrapper = ({children}) => {
  return(
    <Container className="my-5">
      <Row>
        <Col>
          { children }
        </Col>
      </Row>
    </Container>
  )
}

export default ContainerWrapper;
