import React from "react"
import Layout from "../components/Layout"
import PageHeader from '../components/PageHeader'
import ContainerWrapper from '../components/Container'
import { Helmet } from "react-helmet"

const Churches = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Churches - IMSSDARM Asian Division</title>
        <link rel="canonical" href="https://imssdarm.asia/our-churches" />
        <meta name="description" content="Faithfulness to the Ten Commandments included the position of pacifism, based on the belief that it is not possible to accept the holy principles"></meta>
      </Helmet>
      <div className="churches">
        <PageHeader>
          <h1>Our Churches</h1>
        </PageHeader>

        <ContainerWrapper>
          <div className="container">
            <div className="content row">
              <ul class="list-group col-lg-4">
                <li class="list-group-item active" aria-current="true"><h4 className="text-white">List of Asian Churches:</h4></li>
                <li class="list-group-item">Bangladesh Field</li>
                <li class="list-group-item">China Union</li>
                <li class="list-group-item">North Indian Field</li>
                <li class="list-group-item">South Indian Field</li>
                <li class="list-group-item">Japan Field</li>
                <li class="list-group-item">Malaysian Field</li>
                <li class="list-group-item">Myanmar Field</li>
                <li class="list-group-item">Nepal Field</li>
                <li class="list-group-item">Pakistan Field</li>
                <li class="list-group-item">Philippine Union</li>
                <li class="list-group-item">South Korean Field</li>
                <li class="list-group-item">Sri Lankan Field</li>
              </ul>
              <ul class="list-group col-lg-4">
                <li class="list-group-item active" aria-current="true"><h4 className="text-white">Isolated Groups:</h4></li>
                <li class="list-group-item">Egypt</li>
                <li class="list-group-item">Hong Kong</li>
                <li class="list-group-item">Israel</li>
                <li class="list-group-item">Kazakhstan</li>
                <li class="list-group-item">Kyrgyzstan</li>
                <li class="list-group-item">Lebanon</li>
                <li class="list-group-item">Saudi Arabia</li>
                <li class="list-group-item">Thailand</li>
                <li class="list-group-item">Uzbekistan</li>
                <li class="list-group-item">Vietnam</li>
                <li class="list-group-item">Yemen</li>
              </ul>
              <ul class="list-group col-lg-4">
                <li class="list-group-item active" aria-current="true"><h4 className="text-white">Countries with Contacts:</h4></li>
                <li class="list-group-item">Bhutan</li>
                <li class="list-group-item">Cambodia</li>
                <li class="list-group-item">East Timor</li>
                <li class="list-group-item">Iraq</li>
                <li class="list-group-item">Jordan</li>
                <li class="list-group-item">Laos</li>
                <li class="list-group-item">Macau</li>
                <li class="list-group-item">Mongolia</li>
                <li class="list-group-item">Singapore</li>
                <li class="list-group-item">Taiwan</li>
              </ul>
            </div>
          </div>
        </ContainerWrapper>

      </div>
    </Layout>
  )
}

export default Churches;
